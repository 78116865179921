import css from '@styled-system/css'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Flex, P } from 'ui/acro'
import { H2 } from 'ui/bend'

import * as ClassNavData from './data/ClassNavData'
import * as SeriesNavData from './data/SeriesNavData'

import { useIsDesktop } from 'hooks'

const SessionTitle = styled(H2)(() =>
  css({
    color: '#000',
    fontSize: '1.75rem',
    textTransform: 'uppercase',
    margin: 0
  })
)

const NavTitle = styled(P)(() =>
  css({
    fontSize: '1rem',
    letterSpacing: '0.022rem',
    margin: 0,
    color: '#3B3B3B',
    '@media only screen and (max-width: 1200px)': {
      fontWeight: '600',
      lineHeight: '1.5rem'
    }
  })
)

const Heading = styled(Flex)(() =>
  css({
    gap: '1.5rem',
    alignItems: 'center',
    '@media only screen and (max-width: 1200px)': {
      flexDirection: 'column',
      alignItems: 'baseline',
      gap: '0.5rem'
    }
  })
)

const TopCategoryWrapper = styled(Flex)(() =>
  css({
    justifyContent: 'space-between',
    padding: '1.5rem 3.75rem',
    '@media only screen and (max-width: 1200px)': {
      flexDirection: 'column',
      padding: 0
    }
  })
)

const ClassesSection = styled.div`
  flex: 1;
`

const SeriesSection = styled.div`
  flex: 1;
  border-left: 1px solid #cccccc;
  padding: 0 3.75rem;
  @media only screen and (max-width: 1200px) {
    border-left: 0;
    padding: 0;
    border-top: 1px solid #cccccc;
    margin-top: 1rem;
    padding-top: 1rem;
  }
`

const Links = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-auto-flow: dense;
  margin-top: 1.5rem;
  letter-spacing: 0.022rem;
  gap: 1.5rem;

  /* Place the first 8 links in the first column */
  a:nth-child(-n + 8) {
    grid-column: 1;
  }

  /* Place the remaining links in the second column */
  a:nth-child(n + 9) {
    grid-column: 2;
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    a:nth-child(n + 9) {
      grid-column: 1;
    }
  }

  a {
    font-family: 'proxima-nova';
  }
`

const Text = styled(Flex)(() =>
  css({
    marginBottom: '1.22rem',
    fontFamily: 'Arquitecta',
    fontWeight: '900',
    letterSpacing: '0.07rem',
    textTransform: 'uppercase'
  })
)

const TopCategory = ({ hoverCategory }) => {
  const [classesTypes, setClassesTypes] = useState()
  const [seriesTypes, setSeriesTypes] = useState()

  const isDesktop = useIsDesktop()

  useEffect(() => {
    setClassesTypes(ClassNavData[hoverCategory])
    setSeriesTypes(SeriesNavData[hoverCategory])
  }, [hoverCategory])

  return (
    <div>
      {!isDesktop && <Text>{classesTypes?.category}</Text>}
      <TopCategoryWrapper>
        <ClassesSection>
          <Heading>
            <SessionTitle>Classes</SessionTitle>
            <NavTitle>
              Find the perfect workout or wellness class to match your mood.
            </NavTitle>
          </Heading>
          <Links>
            <a
              href={classesTypes?.classFinderLink}
            >{`All ${classesTypes?.category} Classes`}</a>
            {classesTypes?.subcategories?.map((item) => (
              <a href={item.url} key={`classes-${item.title}`}>
                {item.title}
              </a>
            ))}
          </Links>
        </ClassesSection>
        <SeriesSection>
          <Heading>
            <SessionTitle>Series</SessionTitle>
            <NavTitle>
              Follow a guided program to hit your fitness & wellness goals.
            </NavTitle>
          </Heading>
          <Links>
            <a
              href={seriesTypes?.seriesExploreLink}
            >{`All ${seriesTypes?.category} Series`}</a>
            {seriesTypes?.subcategories?.map((item) => (
              <a href={item.url} key={`series-${item.title}`}>
                {item.title}
              </a>
            ))}
          </Links>
        </SeriesSection>
      </TopCategoryWrapper>
    </div>
  )
}

export default TopCategory
